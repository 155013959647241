@import url("https://fonts.googleapis.com/css2?family=Marcellus&display=swap");

.career-section{
    font-family: Helvetica;
}
.career-heading-top h1{
    font-size: 3.5rem;
    font-weight: 400;
}
.career-heading-top p{
    font-size: 1.5rem;
}
.career-heading-top button{
    font-size: 1.1rem;
    width: max-content;
    padding: 10px 30px;
    border-radius: 1000px;
}
.career-img {
  font-family: Helvetica;
  height: auto;
  padding: 100px 0;
  background-image: url("../images/caro.png");
  background-repeat: no-repeat;
  background-position: center;
  color: white;
  text-align: center;
  background-size: cover;
}
@media(max-width:992px){
    .career-heading-top h1{
        font-size: 2.5rem;
    }
    .career-heading-top p{
        font-size: 1rem;
    }
    .career-heading-top button{
        font-size: 1rem;
    }
}
@media(max-width:425px){
    .career-heading-top h1{
        font-size: 2rem;
    }
    .career-heading-top p{
        font-size: 0.85rem;
    }
    .career-heading-top button{
        font-size: 0.85rem;
    }
    .career-img {
        padding: 40px 0;
      }
}
.btn-caro-1-2 {
  background-color: rgb(255, 255, 255);
  color: rgb(0, 0, 0);
  width: 180px;
  padding: 5px 8px;
  border-radius: 20px;
  border: none;
}
.filters{
    padding: 50px 20px 30px 80px;
    font-family: Helvetica;
}
.career-btn-filter{
    background-color: rgb(255, 255, 255);
    color: rgb(0, 0, 0);
    width: max-content;
    padding: 3px 15px;
    border-radius: 20px;
    border: 1px solid black;
    margin:5px 5px;
}
.career-btn-filter-2{
    background-color: rgb(0, 0, 0);
    color: rgb(255, 255, 255);
    width: max-content;
    padding: 5px 30px;
    border-radius: 20px;
    border: 1px solid black;
    margin:0 5px;
}
.career-btn-filter:hover{
    background-color: rgb(0, 0, 0);
    color: rgb(255, 255, 255);
}
.job-posts{
    margin: 0px 80px 20px 80px;
}
.each-post{
    border: 1px solid black;
    padding: 30px;
    border-radius: 30px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
}
@media(max-width:900px){
    .filters{
        padding: 30px 20px 30px 30px;
    }
    .job-posts{
        margin: 10px 30px;
    }
}
@media(max-width:435px){
    .each-post{
        flex-direction: column;
        text-align: center;
    }
    .career-btn-filter-2{
        margin-top: 10px;
    }
    .jcc{
        justify-content: center;
    }
}

