@import url("https://fonts.googleapis.com/css2?family=Marcellus&display=swap");

.product-section {
  font-family: Helvetica;
}
.wall-prod-2 {
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url("../images/ps.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  text-align: center;
  color: rgb(0, 0, 0);
  padding: 200px;
  position: relative;
  z-index: -1;
}
@media(max-width:780px){
    .wall-prod-2 {
        padding: 200px 100px;
      }
}
@media(max-width:600px){
    .wall-prod-2 {
        padding: 200px 50px;
      }
}
.box-abs-txt{
    border: 1px solid black;
    width: max-content;
    padding: 25px;
    margin:auto;
    position: relative;
    top: -40px;
    background-color: #FFFFFF;
}