@import url("https://fonts.googleapis.com/css2?family=Marcellus&display=swap");

.support-section{
    font-family: Helvetica;
}
.support-cards{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    margin: 30px 0;
    flex-wrap: wrap;
}
.support-part{
    padding: 100px 0;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.cards-logo{
    border: 1px solid #B3B3B3;
    border-radius: 20px;
    height: 350px;
    width: 350px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 30px;
    cursor: pointer;
}
@media(max-width:370px){
    .cards-logo{
        height: 300px;
        width: 300px;
    }
}
.cards-logo:hover{
    border: 2px solid black;
}
.logo-cir-s{
    height: 50px;
    width: 50px;
    background-color: black;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
}