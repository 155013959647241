@import url("https://fonts.googleapis.com/css2?family=Marcellus&display=swap");

.product-section {
  font-family: Helvetica;
}
.wall-prod {
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url("../images/product.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  text-align: center;
  color: white;
  padding: 200px;
}
.prod-list {
  display: flex;
  align-items: center;
  gap: 20px;
  padding: 20px 80px;
  overflow-x: scroll;
}
.prod-list::-webkit-scrollbar {
  width: 0px;
  height: 8px;
}

.prod-list::-webkit-scrollbar-thumb {
  background: rgba(40, 40, 102, 0.09);
  border-radius: 20px;
}

.prod-list::-webkit-scrollbar-thumb:hover {
  background: rgba(40, 40, 102, 0.09);
}
.prods-img {
  cursor: pointer;
}
.prods-img:hover {
  text-decoration: underline;
}
.prods-img img {
  height: 130px;
}

@media (max-width: 475px) {
  .prod-list {
    gap: 10px;
    padding: 20px 30px;
  }
  .prods-img img {
    height: 125px;
  }
}
@media (max-width: 400px) {
  .wall-prod {
    padding: 100px;
  }
}

.caro-slide-one {
  height: 300px;
}
.carousel-indicators [data-bs-target] {
  text-indent: 0;
  box-sizing: border-box;
  margin-bottom: 30px;
}
.caro-lr-1 {
  padding: 70px 200px;
}
.caro-left {
  padding: 50px;
  background-color: rgba(40, 40, 102, 0.09);
  border-radius: 20px 0 0 20px;
}
.caro-right {
  border-radius: 0 20px 20px 0;
  padding: 80px 50px;
  background-color: rgba(40, 40, 102, 0.09);
}
.dot-caro {
  background-color: rgb(27, 27, 27);
  height: 8px;
  width: 8px;
  border-radius: 50%;
}
.carousel-indicators .active .dot-caro {
  background-color: rgb(0, 0, 0);
  border: 3.5px solid white;
  outline: 1px solid black;
  height: 14px;
  width: 14px;
  border-radius: 50%;
  margin-top: -2.5px;
}
@media (max-width: 1400px) {
  .caro-lr-1 {
    padding: 70px 100px;
  }
}
@media (max-width: 1190px) {
  .caro-lr-1 {
    padding: 70px 50px;
  }
}
@media (max-width: 1075px) {
  .caro-lr-1 {
    padding: 70px 50px;
  }
  .caro-left {
    padding: 50px 20px;
  }
  .caro-right {
    padding: 80px 20px;
  }
}
@media (max-width: 992px) {
  .caro-left {
    border-radius: 20px 20px 0 0;
    padding: 20px;
  }
  .caro-right {
    border-radius: 0 0 20px 20px;
    padding: 20px;
  }
}
@media (max-width: 585px) {
  .img-trans {
    transform: scale(0.8);
    margin: -50px 0 -80px 0;
  }
}
@media (max-width: 490px) {
  .img-trans {
    transform: scale(0.7);
    margin: -70px 0 -100px 0;
  }
}
@media (max-width: 440px) {
  .img-trans {
    transform: scale(0.7);
    margin: -70px 0 -100px 0;
  }
  .caro-lr-1 {
    padding: 30px;
  }
  .dot-caro {
    margin-top: 50px;
  }
  .carousel-indicators .active .dot-caro {
    margin-top: 47px;
  }
}
@media (max-width: 385px) {
  .img-trans {
    transform: scale(0.6);
    margin: -100px 0 -140px 0;
  }
  .caro-lr-1 {
    padding: 20px;
  }
}

.btn-caro-1-1 {
  border: 1px solid black;
  background-color: black;
  color: white;
  width: 180px;
  padding: 5px 8px;
  border-radius: 20px;
}
.btn-caro-2-1 {
  border: 1px solid black;
  background-color: transparent;
  color: rgb(0, 0, 0);
  width: 180px;
  padding: 5px 8px;
  border-radius: 20px;
}
@media (max-width: 491px) {
  .btn-caro-1-1 {
    width: 140px;
    font-size: 0.85rem;
  }
  .btn-caro-2-1 {
    width: 140px;
    font-size: 0.85rem;
  }
}
@media (max-width: 352px) {
  .btn-caro-1-1 {
    width: 120px;
    font-size: 0.75rem;
  }
  .btn-caro-2-1 {
    width: 120px;
    font-size: 0.75rem;
  }
}


.row-blog-prod{
  display: flex;
  justify-content: center;
  align-items: start;
  flex-wrap: wrap;
  margin-bottom: 50px;
  padding: 0 50px;
}

.img-trans-2{
  margin-left: 40px;
  margin-top: 35px;
}
@media(max-width:1080px){
  .img-trans-2{
    margin-left: 40px;
    transform: scale(0.9);
  } 
  .caro-right-text-2{
    font-size: 0.85rem;
  }
}
@media(max-width:992px){
  .img-trans-2{
    margin-left: 0px;
  } 
  .caro-right-text-2{
    font-size: 1rem;
  }
}
@media(max-width:585px){
  .img-trans-2{
    margin-left: 0px;
    transform: scale(0.75);
    margin: -20px 0 -80px 0;
  } 
  .caro-right-text-2{
    font-size: 1rem;
  }
}
@media (max-width: 385px) {
  .img-trans-2 {
    transform: scale(0.6);
    margin-top: -100px;
    padding-bottom: 10px;
  }
  .caro-lr-1 {
    padding: 20px;
  }
}
