@import url("https://fonts.googleapis.com/css2?family=Marcellus&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

.footer{
    background-image: url("../images/footer.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    font-family: Helvetica;
    color: white;
}
.footer-top{
    padding: 60px 20px 30px 20px;
}
.footer-form{
    text-align: center;
}
.email-footer{
    background-color: transparent;
    border: none;
    border-bottom: 1px solid white;
    outline: none;
    width: 35%;
    color: white;
    padding: 5px;
}
.footer-sub-btn{
    border: none;
    background-color: transparent;
    color: white;
    border-bottom: 1px solid white;
    padding: 5px;
}
.each-logo{
    border: 1px solid white;
    height: 40px;
    width: 40px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}
.logo-each{
    font-size: 1.1rem;
    color: white;
}
.each-logo:hover{
    background-color: white;
    .logo-each{
        color: black;
    }
}
#foot-bold{
    font-family: "Poppins", sans-serif;
}