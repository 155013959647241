@import url("https://fonts.googleapis.com/css2?family=Marcellus&display=swap");

.about-black {
  font-family: Helvetica;
  text-align: center;
  background-color: black;
  color: white;
  padding: 20px;
}
.about-black-below {
  font-family: Helvetica;
  padding: 50px 0;
}
.side-by-side {
  font-family: Helvetica;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  padding: 0 50px;
}
.side-by-side-left-o {
  font-family: Helvetica;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  padding: 0 50px;
}
.side-by-side-left img {
  height: 25vw;
}
.side-by-side-left-o img {
  width: 30vw;
}
@media (max-width: 1125px) {
  .side-by-side {
    padding: 0 30px;
  }
}
@media (max-width: 768px) {
  .side-by-side {
    padding: 0 30px;
    flex-direction: column;
  }
  .side-by-side-left img {
    height: 300px;
  }
  .side-by-side-left-o img {
    width: 350px;
  }
}
@media (max-width: 550px) {
  .side-by-side-left img {
    height: 250px;
  }
  .side-by-side-left-o img {
    width: 300px;
  }
}
@media (max-width: 435px) {
  .side-by-side-left img {
    height: 200px;
  }
  .side-by-side-left-o img {
    width: 220px;
  }
}

.partners {
  font-family: Helvetica;
}
.parts {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 30px;
  flex-wrap: wrap;
  padding: 0 20px;
}
.part-each {
  width: 140px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 100px;
  border-radius: 15px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.15), 0 0px 20px 0 rgba(0, 0, 0, 0.15);
  cursor: pointer;
}



.about-signup{
    font-family: Helvetica;
}
.form-about-su{
    width: max-content;
    margin: auto;
}
.flname{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
}
.flname-text{
    padding: 8px 100px 8px 10px;
    font-size: 0.85rem;
    width: 100%;
    border: 1.5px solid black;
    border-radius: 5px;
    outline: none;
}
.btn-send-ab-su{
    background-color: black;
    color: white;
    border: none;
    border-radius: 5px;
    padding: 8px 0;
}
@media(max-width:610px){
    .flname{
        display: flex;
        gap: 10px;
    }
    .flname-text{
        padding: 8px 50px 8px 10px;
    }
}
@media(max-width:500px){
    .flname{
        display: flex;
        gap: 10px;
    }
    .flname-text{
        padding: 8px 20px 8px 10px;
    }
}
@media(max-width:430px){
    .flname-text{
        padding: 8px 0px 8px 10px;
    }
}
@media(max-width:390px){
    .form-about-su{
        transform: scale(0.85);
    }
    .flname-text{
        padding: 8px 0px 8px 10px;
    }
    .about-section{
        overflow-x: hidden;
    }
}
@media(max-width:346px){
    .form-about-su{
        transform: scale(0.85);
        margin-left: -3px;
    }
}
